/* @flow */

import { combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { withLayoutReducers, withLayoutState, withLayoutSagas, withLayoutMiddlewares } from 'layout/store';

import type { RailsProps, RailsContext } from './types';

export const sagaMiddleware = createSagaMiddleware();

export const rootSaga = withLayoutSagas();

export const initStore = (props: RailsProps, context: RailsContext) =>
  createStore(
    combineReducers(withLayoutReducers()),
    withLayoutState(props, context),
    withLayoutMiddlewares(sagaMiddleware),
  );
