/* @flow */

import * as React from 'react';
import { Provider } from 'react-redux';

import NotFoundContainer from 'app/rescript/bundles/pages-not-found/ui/NotFoundContainer/NotFoundContainer.bs.js';

import IntlProviderWrapper from 'app/rescript/i18n/IntlProviderWrapper.bs.js';
import ScreenSizeContext from 'app/rescript/contexts/ScreenSizeContext.bs.js';
import AdBlockContext from 'app/rescript/contexts/AdBlockContext.bs.js';

import Layout from 'layout';
import type { RailsProps, RailsContext } from './types';

import { initStore, rootSaga, sagaMiddleware } from './store';

const NotFoundPage = (props: RailsProps, context: RailsContext) => {
  const store = initStore(props, context);

  sagaMiddleware.run(rootSaga);

  return () => (
    <Provider store={store}>
      <IntlProviderWrapper>
        <ScreenSizeContext>
          <AdBlockContext>
            <Layout navbar={props.navbar} alerts={props.alerts} impersonatedUser={props.impersonatedUser}>
              <NotFoundContainer />
            </Layout>
          </AdBlockContext>
        </ScreenSizeContext>
      </IntlProviderWrapper>
    </Provider>
  );
};

export default NotFoundPage;
