// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Svg = require("../components/Svg/Svg.bs.js");
var Route = require("../../Route.bs.js");
var React = require("react");

function NotFoundIllustration(props) {
  var title = props.title;
  var title$1 = title !== undefined ? title : "404";
  return React.createElement(Svg.$$Image.make, {
              src: Route.cdnEndpointUrl("/svgs/not_found_illustration.svg"),
              title: title$1,
              className: props.className
            });
}

var make = NotFoundIllustration;

exports.make = make;
/* Svg Not a pure module */
