// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var A = require("../../../../styleguide/components/Link/A.bs.js");
var Cx = require("rescript-classnames/src/Cx.bs.js");
var React = require("react");
var ReactIntl = require("react-intl");
var NotFoundIllustration = require("../../../../styleguide/svgs/NotFoundIllustration.bs.js");
var NotFoundContainerScss = require("./NotFoundContainer.scss").default;

var css = NotFoundContainerScss;

function NotFoundContainer(props) {
  return React.createElement("div", {
              className: Cx.cx([
                    "container",
                    css.notFoundPage
                  ])
            }, React.createElement(NotFoundIllustration.make, {}), React.createElement("h1", {
                  className: css.title
                }, React.createElement(ReactIntl.FormattedMessage, {
                      id: "not-found-page.title",
                      defaultMessage: "Ooops! Page not found"
                    })), React.createElement("p", {
                  className: css.subtitle
                }, React.createElement(ReactIntl.FormattedMessage, {
                      id: "not-found-page.subtitle",
                      defaultMessage: "The page you're looking for is on vacation."
                    }), React.createElement("br", undefined), React.createElement(ReactIntl.FormattedMessage, {
                      id: "not-found-page.subtitle-second-line",
                      defaultMessage: "We suggest you "
                    }), React.createElement(A.make, {
                      href: "/",
                      style: /* Primary */2,
                      children: React.createElement(ReactIntl.FormattedMessage, {
                            id: "not-found-page.home-link",
                            defaultMessage: "back to home"
                          })
                    })));
}

var make = NotFoundContainer;

var $$default = NotFoundContainer;

exports.css = css;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* css Not a pure module */
